import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import dayjs from "dayjs";

import { subcategories } from "@aclymatepackages/subcategories";
import { DEFAULT_START_DATE } from "@aclymatepackages/constants";

import { getAccountCollectionAndId } from "../otherHelpers";
import { fetchOurApi } from "../utils/apiCalls";

import useAccountingData from "../hooks/accountingData";
import { addCollectionDoc, updateDocRef, setDocRef } from "../firebase";
import { AdminDataContext } from "./adminData";
import { PlatformLayoutContext } from "./platformLayout";

const TransactionsContext = createContext();

const sortTransaction = ({
  transaction,
  transactionsObj,
  mostRecentAccountingDate,
}) => {
  const {
    archived,
    date,
    emissionDate,
    subcategory,
    event,
    employees = "",
    office,
    knownVendor,
    name,
    value,
    vendor,
  } = transaction;

  const utilitiesSubcategories = ["gas", "electricity", "utilities"];

  const pushToTagArray = (type, { id }, transaction) => {
    const propName = `${type}_${id}`;

    const array = transactionsObj[propName];
    if (!array) {
      return (transactionsObj[propName] = [transaction]);
    }

    return array.push(transaction);
  };

  const buildEmissionStatusObj = (
    { id, date, status, subcategory },
    mostRecentAccountingDate
  ) => {
    if (dayjs(date).isAfter(dayjs())) {
      return { status: "cleared", severity: 2 };
    }

    if (
      mostRecentAccountingDate &&
      dayjs(date).isBefore(dayjs(mostRecentAccountingDate))
    ) {
      return { status: "locked", severity: 0 };
    }

    if (id && status !== "confirmed" && subcategory !== "spend-based") {
      return { status: "unconfirmed", severity: 3 };
    }

    return { status: "confirmed", severity: 1 };
  };

  const statusObj = buildEmissionStatusObj(
    transaction,
    mostRecentAccountingDate
  );

  const { scope } =
    subcategories.find(
      (subcategoryObj) => subcategoryObj.subcategory === subcategory
    ) || {};

  const formattedTransaction = {
    ...transaction,
    ...statusObj,
    scope,
    date: new Date(emissionDate || date),
    name: name || `${value ? `$${Math.abs(value).toFixed(2)}- ` : ""}${vendor}`,
    type: "transaction",
  };
  const { status } = formattedTransaction;

  if (archived) {
    return;
  }

  if (status === "unconfirmed") {
    transactionsObj.unconfirmedTransactions.push(formattedTransaction);
  }

  if (utilitiesSubcategories.includes(subcategory)) {
    transactionsObj.utilitiesTransactions.push(formattedTransaction);
  }

  if (!event) {
    transactionsObj.nonEventTransactions.push(formattedTransaction);
  }

  if (event) {
    pushToTagArray("event", event, formattedTransaction);
  }

  if (employees) {
    employees.forEach((employee) =>
      pushToTagArray("employee", employee, formattedTransaction)
    );
  }

  if (office) {
    pushToTagArray("office", office, formattedTransaction);
  }

  if (knownVendor) {
    pushToTagArray("vendor", knownVendor, formattedTransaction);
  }

  transactionsObj.transactions.push(formattedTransaction);
  return transactionsObj;
};

const buildTransactionsObject = ({
  transactions: dbTransactions,
  mostRecentAccountingDate = DEFAULT_START_DATE,
}) => {
  let transactionsObj = {
    unconfirmedTransactions: [],
    utilitiesTransactions: [],
    nonEventTransactions: [],
    transactions: [],
  };

  dbTransactions.forEach((transaction) =>
    sortTransaction({ transaction, transactionsObj, mostRecentAccountingDate })
  );

  return transactionsObj;
};

export const TransactionsContextProvider = ({ children }) => {
  const [{ mostRecentAccountingDate }, accountingDataLoading] =
    useAccountingData();
  const { viewMode } = useContext(PlatformLayoutContext) || {};
  const { adminData } = useContext(AdminDataContext) || {};
  const { transactions: adminTransactions = [] } = adminData || {};

  const [transactionsObj, setTransactionsObj] = useState({});
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [adminTransactionsObj, setAdminTransactionsObj] = useState({});

  const fetchTransactions = useCallback(
    (setLoading = setTransactionsLoading) => {
      setLoading(true);
      const { id: accountId } = getAccountCollectionAndId();

      fetchOurApi({
        path: "/transactions/fetch-account-transactions",
        method: "POST",
        data: { accountId },
        callback: ({ transactions }) => {
          const processedTransactions = buildTransactionsObject({
            transactions,
            mostRecentAccountingDate,
          });

          setTransactionsObj(processedTransactions);
          return setLoading(false);
        },
      });
    },
    [mostRecentAccountingDate]
  );

  useEffect(() => {
    if (!accountingDataLoading && transactionsLoading) {
      fetchTransactions();
    }
  }, [accountingDataLoading, transactionsLoading, fetchTransactions]);

  useEffect(() => {
    if (viewMode === "admin") {
      const processedTransactions = buildTransactionsObject({
        transactions: adminTransactions,
        mostRecentAccountingDate,
      });
      return setAdminTransactionsObj(processedTransactions);
    }
  }, [viewMode, adminTransactions, mostRecentAccountingDate]);

  const addNewLocalTransaction = (transaction) => {
    const newTransactionsObj = sortTransaction({
      transaction,
      transactionsObj,
      mostRecentAccountingDate,
    });
    return setTransactionsObj({ ...newTransactionsObj });
  };

  const onNewTransaction = async (transaction) => {
    const transactionId = await addCollectionDoc("transactions", transaction);
    return addNewLocalTransaction({ ...transaction, id: transactionId });
  };

  const singleUpdateTransactionObj = (transactionId, updateObj) => {
    const { transactions } = transactionsObj;

    let thisUpdatedTransaction = {};
    let otherTransactions = [];
    transactions.forEach((transaction) => {
      if (transaction.id === transactionId) {
        return (thisUpdatedTransaction = { ...transaction, ...updateObj });
      }
      return otherTransactions.push(transaction);
    });

    const updatedTransactionsObj = buildTransactionsObject({
      transactions: [...otherTransactions, thisUpdatedTransaction],
      mostRecentAccountingDate,
    });
    return setTransactionsObj({ ...updatedTransactionsObj }); //If you don't do this spread, then the component doesn't re-render
  };

  const onUpdateTransaction = async (transactionId, updateObj) => {
    updateDocRef("transactions", transactionId, updateObj);
    return singleUpdateTransactionObj(transactionId, updateObj);
  };

  const onSetTransaction = async (transactionId, updateObj) => {
    setDocRef("transactions", transactionId, updateObj);
    return singleUpdateTransactionObj(transactionId, updateObj);
  };

  const onBatchUpdateTransactions = async (
    transactionIds,
    updateObj,
    dbUpdateFunction = updateDocRef
  ) => {
    const { transactions } = transactionsObj;
    let updatableTransactions = [];
    let otherTransactions = [];

    Promise.all(
      transactionIds.map((id) =>
        dbUpdateFunction("transactions", id, updateObj)
      )
    );

    transactions.forEach((transaction) => {
      if (transactionIds.includes(transaction.id)) {
        return updatableTransactions.push({ ...transaction, ...updateObj });
      }
      return otherTransactions.push(transaction);
    });
    const updatedTransactionsObj = buildTransactionsObject({
      transaction: [...otherTransactions, ...updatableTransactions],
      mostRecentAccountingDate,
    });
    return setTransactionsObj({ ...updatedTransactionsObj });
  };

  return (
    <TransactionsContext.Provider
      value={{
        transactionsObj:
          viewMode === "admin" ? adminTransactionsObj : transactionsObj,
        transactionsLoading,
        addNewLocalTransaction,
        onNewTransaction,
        onUpdateTransaction,
        onSetTransaction,
        onBatchUpdateTransactions,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};

const useTransactionsContext = () => useContext(TransactionsContext);
export default useTransactionsContext;
