import React, { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import {
  StyledEngineProvider,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Box,
  Avatar,
  Switch,
  useTheme,
  ThemeProvider,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { LoadingButton, Select, TextField } from "@aclymatepackages/atoms";
import { YesNoQuestion } from "@aclymatepackages/modules";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import mainTheme, { mergeDarkTheme } from "@aclymatepackages/themes";
import { utilitiesProviders } from "@aclymatepackages/lists";
import { subcategories } from "@aclymatepackages/subcategories";

import SettingsObjectDetailsSlider from "../../SettingsObjectDetailsSlider";
import EmissionsDetails from "../../EmissionsDetails";

import WarningPopup from "../../../../atoms/notifications/WarningPopup";
import EmissionsDetailsWarning from "../../../../atoms/notifications/EmissionsDetailsWarning";
import DatePicker from "../../../../atoms/mui/DatePicker";
import AnalyticsFeatureIcon from "../../../../atoms/premium-analytics/AnalyticsFeatureIcon";

import PlacesAutocomplete from "../../../../inputs/autocomplete/PlacesAutocomplete";
import FacilityTypeSelect from "../../../../inputs/offices/FacilityTypeSelect";
import ElectricIntensityAndUnitsInput from "../../../../inputs/offices/ElectricIntensityAndUnitsInput";
import ElectricRenewablesPercentageInput from "../../../../inputs/offices/ElectricRenewablesPercentageInput";
import OfficeInput from "../../../../inputs/tags/OfficeInput";
import MultipartForm from "../../../../inputs/flows/MultipartForm";
import LinkUtilitiesForm from "../../../../inputs/offices/LinkUtilitiesForm";

import {
  useOfficeStatuses,
  electricRenewablesAndIntensitySaveEnabled,
  useNewOfficesSubmit,
  useBuildNewOfficeInputFormRows,
} from "../../../../../helpers/components/offices";
import {
  buildNewEmployeeStatusLinks,
  sendEmployeeSurvey,
} from "../../../../../helpers/components/employees";
import { useSharedFormLoading } from "../../../../../helpers/components/inputs";
import { serviceClassDescription } from "../../../../../helpers/components/utilitiesApi";
import {
  useAccountData,
  useCachedFirebaseCrud,
  useCachedDisplayData,
} from "../../../../../helpers/firebase";
import { setAddress } from "../../../../../helpers/utils/geography";
import { fetchOurApi } from "../../../../../helpers/utils/apiCalls";
import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";
import {
  useStarterTierSubscriptionFlags,
  useSubscriptionType,
} from "../../../../../helpers/hooks/companyData";
import useAccountingData from "../../../../../helpers/hooks/accountingData";

const LinkedUtilityDisplayBlock = ({ type, linkedUtility }) => {
  const { palette } = useTheme();

  const { utilityId, pairedMeters = [] } = linkedUtility || {};
  const { icon } = subcategories.find(
    ({ subcategory }) => subcategory === type
  );
  const { name: utilityName } = utilitiesProviders.find(
    ({ utilityId: providerId }) => providerId === utilityId
  );
  const backgroundColor = palette[type].main;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mergeDarkTheme}>
        <Box
          style={{
            backgroundColor,
            borderRadius: "15px",
          }}
          p={2}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2} alignItems="center" wrap="nowrap">
              <Grid item>
                <Avatar
                  style={{
                    fontSize: "1rem",
                    height: "25px",
                    width: "25px",
                    backgroundColor: "white",
                    borderStyle: "solid",
                    borderColor: "white",
                  }}
                >
                  {icon}
                </Avatar>
              </Grid>
              <Grid item>
                <Typography variant="body1" color="primary">
                  {utilityName}
                </Typography>
              </Grid>
            </Grid>
            {pairedMeters.map(({ serviceAddress, serviceClass }, idx) => (
              <Grid item>
                <Typography variant="body2" color="primary">{`Meter #${
                  idx + 1
                } (${serviceClassDescription(serviceClass)})`}</Typography>
                <Typography variant="body2" color="primary">
                  {serviceAddress}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const EditOfficeDetails = ({
  modifyOffice,
  setModifyOffice,
  utilitiesConfig,
  setUtilitiesConfig,
  companyStartDate,
}) => {
  const [{ earliestNewEmissionDate }] = useAccountingData();

  const {
    address: modifyAddress,
    sqFootage: modifySqFootage,
    facilityType: modifyFacilityType,
    combinedUtility: modifyCombinedUtility,
    electricUtility: modifyElectricUtility,
    gasUtility: modifyGasUtility,
    startDate: modifyStartDate,
    electricRenewablesPercentage: modifyElectricRenewablesPercentage,
    electricCarbonIntensity: modifyElectricCarbonIntensity,
    electricCarbonIntensityUnits: modifyElectricCarbonIntensityUnits,
    isGasInIncludedUtilities: modifyIsGasInIncludedUtilities,
    linkedElectricUtility,
    linkedGasUtility,
    linkedCombinedUtility,
    doesOfficeHaveGasUtility: modifyDoesOfficeHaveGasUtility,
  } = modifyOffice;

  const editOffice = (field) => (value) =>
    editObjectData(setModifyOffice, field, value);

  const utilitiesSelectOptions = [
    { label: "Utilities in lease", value: "utilitiesInLease" },
    { label: "Single Utility", value: "combinedUtilities" },
    { label: "Separate Utilities", value: "separateUtilities" },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <PlacesAutocomplete
          label="What's the address?"
          place={modifyAddress || null}
          editPlace={setAddress(editOffice("address"))}
          size="small"
        />
      </Grid>
      <Grid item sm={5}>
        <TextField
          label="Sq. Footage"
          value={modifySqFootage}
          setValue={editOffice("sqFootage")}
        />
      </Grid>
      <Grid item sm={7}>
        <FacilityTypeSelect
          facilityType={modifyFacilityType}
          editFacilityType={editOffice("facilityType")}
        />
      </Grid>
      <Grid item sm={12}>
        <DatePicker
          label="Start Date"
          disabled={
            earliestNewEmissionDate &&
            dayjs(modifyStartDate).isBefore(dayjs(earliestNewEmissionDate))
          }
          date={modifyStartDate || companyStartDate}
          minDate={companyStartDate}
          maxDate={new Date()}
          editDate={(date) => editOffice("startDate")(new Date(date))}
        />
      </Grid>
      <Grid item sm={12}>
        <Select
          label="Utilities Settings"
          size="small"
          value={utilitiesConfig}
          editValue={setUtilitiesConfig}
          options={utilitiesSelectOptions}
        />
      </Grid>
      {utilitiesConfig === "combinedUtilities" && (
        <Grid item sm={12}>
          {linkedCombinedUtility ? (
            <LinkedUtilityDisplayBlock
              type="utilities"
              linkedUtility={linkedCombinedUtility}
            />
          ) : (
            <TextField
              label="Utility Provider"
              size="small"
              value={modifyCombinedUtility}
              setValue={editOffice("combinedUtility")}
            />
          )}
        </Grid>
      )}
      {utilitiesConfig === "separateUtilities" && (
        <>
          <Grid item sm={6}>
            {linkedGasUtility ? (
              <LinkedUtilityDisplayBlock
                type="gas"
                linkedUtility={linkedGasUtility}
              />
            ) : (
              <TextField
                label="Gas Utility"
                size="small"
                disabled={modifyDoesOfficeHaveGasUtility === false}
                value={
                  !(modifyDoesOfficeHaveGasUtility === false)
                    ? modifyGasUtility
                    : ""
                }
                setValue={editOffice("gasUtility")}
              />
            )}
          </Grid>
          <Grid item sm={6}>
            {linkedElectricUtility ? (
              <LinkedUtilityDisplayBlock
                type="electricity"
                linkedUtility={linkedElectricUtility}
              />
            ) : (
              <TextField
                label="Electric Utility"
                size="small"
                value={modifyElectricUtility}
                setValue={editOffice("electricUtility")}
              />
            )}
          </Grid>
        </>
      )}
      {utilitiesConfig === "utilitiesInLease" ? (
        <>
          <Grid item sm={12}>
            <ElectricRenewablesPercentageInput
              electricRenewablesPercentage={modifyElectricRenewablesPercentage}
              editElectricRenewablesPercentage={editOffice(
                "electricRenewablesPercentage"
              )}
            />
          </Grid>
          {modifyElectricRenewablesPercentage !== "100" && (
            <ElectricIntensityAndUnitsInput
              electricCarbonIntensity={modifyElectricCarbonIntensity}
              editElectricCarbonIntensity={editOffice(
                "electricCarbonIntensity"
              )}
              electricCarbonIntensityUnits={modifyElectricCarbonIntensityUnits}
              editElectricCarbonIntensityUnits={editOffice(
                "electricCarbonIntensityUnits"
              )}
            />
          )}
          <Grid
            item
            container
            sm={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="body1">
                Is gas included in your utilities?
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={!(modifyIsGasInIncludedUtilities === false)}
                onChange={(e) =>
                  editOffice("isGasInIncludedUtilities")(e.target.checked)
                }
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          item
          container
          sm={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="body1">
              Does this office have a gas utility?
            </Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={!(modifyDoesOfficeHaveGasUtility === false)}
              onChange={(e) =>
                editOffice("doesOfficeHaveGasUtility")(e.target.checked)
              }
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const FooterWithWarnings = ({
  showCloseWarning,
  setShowCloseWarning,
  warningType,
  saveEnabled,
  editsDate,
  setEditsDate,
  onSave,
  saveLoading,
  resetOfficeBackToOriginal,
  setOfficeMovingInterfaceOpen,
  mostRecentChangeDate,
  officeHasNoEmployees,
  closeOfficeWithoutEmployees,
}) => {
  const [{ startDate: companyStartDate }] = useAccountData();
  const [{ earliestNewEmissionDate }] = useAccountingData();

  const buildWarningProps = () => {
    if (showCloseWarning) {
      return {
        title: "When did you close this office?",
        input: (
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item sm={7}>
              <DatePicker
                darkTheme
                label="Please select a date"
                size="small"
                date={editsDate}
                editDate={setEditsDate}
                minDate={
                  earliestNewEmissionDate ||
                  mostRecentChangeDate ||
                  companyStartDate
                }
                maxDate={new Date()}
              />
            </Grid>
            <Grid item>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={mergeDarkTheme}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => closeOfficeWithoutEmployees(editsDate)}
                  >
                    Close Office
                  </Button>
                </ThemeProvider>
              </StyledEngineProvider>
            </Grid>
          </Grid>
        ),
      };
    }

    const warningTypes = {
      date: {
        title:
          "You need to specify the date that these changes occurred for this office. ",
        input: (
          <DatePicker
            darkTheme
            label="When did you make these changes to this office?"
            size="small"
            date={editsDate}
            editDate={setEditsDate}
            minDate={
              earliestNewEmissionDate ||
              mostRecentChangeDate ||
              companyStartDate
            }
            maxDate={new Date()}
          />
        ),
        secondaryAction: (
          <ThemeProvider theme={mergeDarkTheme}>
            <Grid container justifyContent="center">
              <Grid item>
                <Button onClick={() => onSave()} color="primary">
                  This office wasn't changed
                </Button>
              </Grid>
            </Grid>
          </ThemeProvider>
        ),
      },
      moving: {
        title: "You're changing this office's address?",
        input: (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              xs={12}
            >
              <Typography variant="body2" color="textPrimary">
                Instead of just moving this office, would you instead like to
                close this office and open a new one?
              </Typography>
            </Grid>
            <Grid item>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={mergeDarkTheme}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setOfficeMovingInterfaceOpen(true)}
                  >
                    Yes
                  </Button>
                </ThemeProvider>
              </StyledEngineProvider>
            </Grid>
            <Grid item>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={mergeDarkTheme}>
                  <Button onClick={() => resetOfficeBackToOriginal()}>
                    No
                  </Button>
                </ThemeProvider>
              </StyledEngineProvider>
            </Grid>
          </Grid>
        ),
      },
    };

    return warningTypes[warningType] || {};
  };

  const {
    title: warningTitle,
    input: warningInput,
    secondaryAction,
  } = buildWarningProps();

  const onDeleteClick = () => {
    if (officeHasNoEmployees) {
      return setShowCloseWarning(true);
    }

    return setOfficeMovingInterfaceOpen(true);
  };

  return (
    <div style={{ position: "relative" }}>
      {warningTitle && (
        <WarningPopup
          warningTitle={warningTitle}
          warningInput={warningInput}
          secondaryAction={secondaryAction}
        />
      )}
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Tooltip title="Close this office">
            <span>
              <IconButton onClick={onDeleteClick} size="large">
                <DeleteForeverIcon color="error" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <LoadingButton
            isLoading={saveLoading}
            label="Save Changes"
            color="secondary"
            disabled={!saveEnabled}
            onClick={onSave}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const MovingEmployeesToAnotherOffice = ({
  oldOffice,
  selectedOffice,
  setSelectedOffice,
  moveStartDate,
  setMoveStartDate,
  isSelectedOffice,
  setIsSelectedOffice,
}) => {
  const [companyOffices] = useCachedDisplayData("offices");

  const [{ earliestNewEmissionDate }] = useAccountingData();
  const [{ startDate: companyStartDate }] = useAccountData();
  const { id: oldOfficeId, startDate: oldOfficeStartDate } = oldOffice;
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [activePopper, setActivePopper] = useState("");

  const availableOffices = companyOffices.filter(
    ({ id: officeId, endDate }) => officeId !== oldOfficeId && !endDate
  );

  const propsObj = {
    popperAnchorEl,
    setPopperAnchorEl,
    activePopper,
    setActivePopper,
    disableInputCardResizing: true,
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <YesNoQuestion
              question={`Would you like all current employees in ${oldOffice.name} to move to another office?`}
              value={isSelectedOffice}
              setValue={setIsSelectedOffice}
            />
          </Grid>
          {isSelectedOffice && (
            <>
              <Grid item>
                <Typography variant="h6" align="center">
                  {`Select an office that you would like all current employees in ${oldOffice.name} to move to.`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <OfficeInput
                  office={selectedOffice}
                  setOffice={setSelectedOffice}
                  title="Select an Office"
                  transactionSubcategory="anyUtilities"
                  position="center"
                  availableOffices={availableOffices}
                  {...propsObj}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              When did this office move occur?
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="Move date"
              darkTheme={true}
              date={moveStartDate}
              minDate={
                earliestNewEmissionDate ||
                oldOfficeStartDate ||
                companyStartDate
              }
              maxDate={new Date()}
              editDate={(date) => setMoveStartDate(new Date(date))}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const MovingEmployeesToNewOfficeQuestion = ({
  oldOffice,
  moveEmployeesToNewOffice,
  sendSurveyToEmployees,
}) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={mainTheme}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Typography variant="h6" align="center">
            {`Would you like all of the current employees in ${oldOffice.name} to now commute to the new office you just created?`}
          </Typography>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              color="primary"
              onClick={() => moveEmployeesToNewOffice()}
              variant="contained"
              endIcon={<ChevronRightIcon fontSize="large" />}
            >
              Yes
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              onClick={() => sendSurveyToEmployees()}
              variant="contained"
              endIcon={<ChevronRightIcon fontSize="large" />}
            >
              No
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  </StyledEngineProvider>
);

const useCloseOffice = () => {
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  return async (closedOffice, closedOfficeEndDate) => {
    const { id: closedOfficeId, changes: closedOfficeChanges = [] } =
      closedOffice;

    const closeOfficeChange = {
      date: new Date(),
      status: "closed",
      endDate: closedOfficeEndDate,
    };
    const oldOfficeNewChanges = [closeOfficeChange, ...closedOfficeChanges];

    await updateCollectionDoc("offices", closedOfficeId, {
      changes: oldOfficeNewChanges,
      endDate: closedOfficeEndDate,
    });
  };
};

const useMoveEmployeesToOffice = () => {
  const [employees] = useCachedDisplayData("employees");

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const closeOffice = useCloseOffice();

  const findEmployeesThatCommuteToOffice = ({ id: officeId }) => {
    return employees.filter(({ commuteSchedules }) => {
      if (!Array.isArray(commuteSchedules)) {
        return false;
      }
      const allCommuteEndpoints = commuteSchedules.flatMap(
        ({ commuteEndpoints }) => commuteEndpoints
      );
      return allCommuteEndpoints.find(({ id }) => id === officeId);
    });
  };

  const modifyCommuteEndpointToNewOffice = async (
    commuteSchedule,
    newOffice,
    newOfficeId,
    oldOffice
  ) => {
    const { commuteEndpoints, home } = commuteSchedule;
    const { address: employeeHomeAddress } = home;
    const { id: oldOfficeId } = oldOffice;
    const { address: newCommuteAddress, name: newOfficeName } = newOffice;

    return await Promise.all(
      commuteEndpoints.map(async (commuteEndpoint) => {
        const { id: currentOfficeId } = commuteEndpoint;

        if (currentOfficeId === oldOfficeId) {
          const { totalMileage: newOneWayDistanceMi } = await fetchOurApi({
            path: "/calcs/cars/directions-mileage",
            method: "POST",
            data: {
              to: newCommuteAddress,
              from: employeeHomeAddress,
            },
            callback: (res) => res,
          });

          return {
            ...commuteEndpoint,
            id: newOfficeId,
            name: newOfficeName,
            address: newCommuteAddress,
            oneWayDistanceMi: newOneWayDistanceMi,
          };
        }

        return commuteEndpoint;
      })
    );
  };

  const changeEmployeeCommuteEndpointToNewOffice = async (
    { commuteSchedules },
    newOffice,
    newOfficeId,
    oldOffice,
    moveStartDate
  ) =>
    await Promise.all(
      commuteSchedules.map(async (commuteSchedule, idx) =>
        idx === 0
          ? {
              ...commuteSchedule,
              startDate: dayjs(moveStartDate).toDate(),
              commuteEndpoints: await modifyCommuteEndpointToNewOffice(
                commuteSchedule,
                newOffice,
                newOfficeId,
                oldOffice
              ),
            }
          : commuteSchedule
      )
    );

  const changeEmployeesCommuteSchedulesToNewOffice = async (
    employees,
    newOffice,
    newOfficeId,
    oldOffice,
    moveStartDate
  ) => {
    const employeesThatCommuteToNewOffice = await Promise.all(
      employees.map(async (employee) => ({
        ...employee,
        commuteSchedules: await changeEmployeeCommuteEndpointToNewOffice(
          employee,
          newOffice,
          newOfficeId,
          oldOffice,
          moveStartDate
        ),
      }))
    );

    return employeesThatCommuteToNewOffice.map(
      ({ id: employeeId, commuteSchedules: newCommuteSchedulesToNewOffice }) =>
        updateCollectionDoc("employees", employeeId, {
          commuteSchedules: newCommuteSchedulesToNewOffice,
        })
    );
  };

  const endEmployeesCurrentCommuteSchedules = (employees, oldOfficeEndDate) =>
    employees.map((employee) => {
      const { commuteSchedules: currentCommuteSchedules } = employee;
      const [currentCommuteScheduleCopy, ...previousCommuteSchedules] =
        currentCommuteSchedules;
      const endedCurrentCommuteSchedule = {
        ...currentCommuteScheduleCopy,
        endDate: oldOfficeEndDate,
      };
      const newCommuteSchedules = [
        currentCommuteScheduleCopy,
        endedCurrentCommuteSchedule,
        ...previousCommuteSchedules,
      ];

      return {
        ...employee,
        commuteSchedules: newCommuteSchedules,
      };
    });

  const sendOldOfficeCommutersSurveys = async (employees) =>
    await Promise.all(
      employees.map(async (employee) => {
        const { id: employeeId } = employee;
        await sendEmployeeSurvey({ employee });

        return await updateCollectionDoc("employees", employeeId, {
          links: buildNewEmployeeStatusLinks(employee, "incomplete"),
        });
      })
    );

  const changeEmployeesToCommuteToNewOffice = async ({
    newOffice,
    newOfficeId,
    oldOffice,
    givenMoveStartDate,
  }) => {
    const { startDate: newOfficeStartDate } = newOffice;
    const moveStartDate = givenMoveStartDate || newOfficeStartDate;
    const oldOfficeEndDate = dayjs(moveStartDate).subtract(1, "day").toDate();
    const commuteEmployeesToOldOffice =
      findEmployeesThatCommuteToOffice(oldOffice);

    const employeesWithEndedCurrentCommuteSchedules =
      endEmployeesCurrentCommuteSchedules(
        commuteEmployeesToOldOffice,
        oldOfficeEndDate
      );

    await changeEmployeesCommuteSchedulesToNewOffice(
      employeesWithEndedCurrentCommuteSchedules,
      newOffice,
      newOfficeId,
      oldOffice,
      moveStartDate
    );
    return await closeOffice(oldOffice, oldOfficeEndDate);
  };

  const sendSurveysToOldOfficeCommuters = async ({
    oldOffice,
    givenMoveStartDate,
  }) => {
    const oldOfficeEndDate = dayjs(givenMoveStartDate)
      .subtract(1, "day")
      .toDate();
    const commuteEmployeesToOldOffice =
      findEmployeesThatCommuteToOffice(oldOffice);
    await sendOldOfficeCommutersSurveys(commuteEmployeesToOldOffice);
    return await closeOffice(oldOffice, oldOfficeEndDate);
  };

  return {
    changeEmployeesToCommuteToNewOffice,
    sendSurveysToOldOfficeCommuters,
  };
};

const useMoveToNewOffice = ({ oldOffice, setOpen }) => {
  const isMountedRef = useRef(true);
  const [offices, officesLoading] = useCachedDisplayData("offices");
  const buildNewOfficeInputFormRows = useBuildNewOfficeInputFormRows();
  const onNewOfficesSubmit = useNewOfficesSubmit();
  const { setFormLoading } = useSharedFormLoading();
  const { activateSnackbar } = useContext(PlatformLayoutContext);
  const {
    changeEmployeesToCommuteToNewOffice,
    sendSurveysToOldOfficeCommuters,
  } = useMoveEmployeesToOffice();
  const [{ startDate: companyStartDate, name: companyName }] = useAccountData();
  const [{ isPaid }] = useSubscriptionType("saas");
  const { isStarterTier } = useStarterTierSubscriptionFlags();
  const utilityApiFeatures = useFeatureIsOn("utilityapi-features");

  const [newOffice, setNewOffice] = useState({});
  const [selectedOffice, setSelectedOffice] = useState();
  const [moveStartDate, setMoveStartDate] = useState();
  const [isSelectedOffice, setIsSelectedOffice] = useState();

  const editOffice = (field, value) =>
    editObjectData(setNewOffice, field, value);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!officesLoading && isMountedRef.current) {
      setNewOffice({ name: `Office #${offices.length}` });
    }
  }, [officesLoading, offices.length]);

  const generateOfficeInfo = async (selectedOffice) => {
    if (selectedOffice) {
      const { id: selectedOfficeId } = selectedOffice;

      return {
        newOfficeId: selectedOfficeId,
        newOffice: selectedOffice,
      };
    }

    const { newOfficeIds } = await onNewOfficesSubmit([newOffice]);
    const [newOfficeId] = newOfficeIds;

    return {
      newOfficeId,
      newOffice,
    };
  };

  const moveEmployeesToNewOffice = async (selectedOffice) => {
    setFormLoading(true);
    const { newOfficeId, newOffice } = await generateOfficeInfo(selectedOffice);

    await changeEmployeesToCommuteToNewOffice({
      newOffice,
      newOfficeId,
      oldOffice,
      givenMoveStartDate: moveStartDate,
    });

    const snackbarMessage = `All of the employees in ${oldOffice.name} now commute to ${newOffice.name} and ${oldOffice.name} was closed.`;
    activateSnackbar({ message: snackbarMessage, alert: "success" });
    setFormLoading(false);
    setOpen(false);
  };

  const sendSurveyToEmployees = async (isCreateNewOffice) => {
    setFormLoading(true);
    isCreateNewOffice && (await onNewOfficesSubmit([newOffice]));
    const { startDate: newOfficeStartDate } = newOffice;

    await sendSurveysToOldOfficeCommuters({
      oldOffice,
      givenMoveStartDate: moveStartDate || newOfficeStartDate,
    });

    const snackbarMessage = isCreateNewOffice
      ? `All of the employees in ${oldOffice.name} were sent surveys to update their commute schedules, ${newOffice.name} was created, and ${oldOffice.name} was closed.`
      : `All of the employees in ${oldOffice.name} were sent surveys to update their commute schedules and ${oldOffice.name} was closed.`;
    activateSnackbar({ message: snackbarMessage, alert: "success" });
    setFormLoading(false);
    setOpen(false);
  };

  const FormBottomInput = () => (
    <MovingEmployeesToNewOfficeQuestion
      oldOffice={oldOffice}
      moveEmployeesToNewOffice={() => moveEmployeesToNewOffice()}
      sendSurveyToEmployees={() => sendSurveyToEmployees(true)}
    />
  );

  const moveToNewOfficeForm = [
    {
      label: newOffice.name,
      title: newOffice.name,
      rows: buildNewOfficeInputFormRows({
        office: newOffice,
        editOffice,
        index: 0,
        companyStartDate,
        companyName,
        isUtilityApiAccessible: isPaid && !isStarterTier && utilityApiFeatures,
      }),
      FormBottomInput,
    },
  ];

  const moveToAnotherOfficeForm = [
    {
      label: "Move to another office",
      input: (
        <MovingEmployeesToAnotherOffice
          oldOffice={oldOffice}
          selectedOffice={selectedOffice}
          setSelectedOffice={setSelectedOffice}
          moveStartDate={moveStartDate}
          setMoveStartDate={setMoveStartDate}
          isSelectedOffice={isSelectedOffice}
          setIsSelectedOffice={setIsSelectedOffice}
        />
      ),
      buttonDisabled: isSelectedOffice === undefined || !moveStartDate,
      buttonText: "Submit",
      onAdvanceForm: () =>
        selectedOffice
          ? moveEmployeesToNewOffice(selectedOffice)
          : sendSurveyToEmployees(),
    },
  ];

  return { moveToNewOfficeForm, moveToAnotherOfficeForm };
};

const MovingOfficeInterface = ({ setOpen, oldOffice }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const { moveToNewOfficeForm, moveToAnotherOfficeForm } = useMoveToNewOffice({
    oldOffice,
    setOpen,
  });

  const forms = {
    newOffice: moveToNewOfficeForm,
    moveOffice: moveToAnotherOfficeForm,
  };

  return (
    <MultipartForm
      type="officeMoving"
      forms={forms[selectedOption]}
      onClose={() => setOpen(false)}
      setOption={setSelectedOption}
      selectedOption={selectedOption}
      submitLoadingText="Please wait while we complete your office moving process..."
    />
  );
};

const useEditOfficeDetails = (office, setSelectedOffice) => {
  const {
    currentUtilities,
    changes = [],
    id,
    electricRenewablesPercentage,
    electricCarbonIntensity,
    electricCarbonIntensityUnits,
    currentCommuteEmployees = [],
    utilitiesConfig: initialUtilitiesConfig,
  } = office;

  const { utilitiesInLease } = currentUtilities || {};

  const originalOffice = { ...office, ...currentUtilities };

  const { updateCollectionDoc } = useCachedFirebaseCrud();
  const [{ startDate: companyStartDate }] = useAccountData();

  const closeOffice = useCloseOffice();
  const [utilitiesConfig, setUtilitiesConfig] = useState(
    initialUtilitiesConfig
  );
  const [showCloseWarning, setShowCloseWarning] = useState(false);
  const [modifyOffice, setModifyOffice] = useState(originalOffice);
  const [editsDate, setEditsDate] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [officeMovingInterfaceOpen, setOfficeMovingInterfaceOpen] =
    useState(false);

  const {
    combinedUtility: modifyCombinedUtility,
    gasUtility: modifyGasUtility,
    electricUtility: modifyElectricUtility,
    facilityType: modifyFacilityType,
    electricRenewablesPercentage: modifyElectricRenewablesPercentage = 0,
    electricCarbonIntensity: modifyElectricCarbonIntensity = 0,
    electricCarbonIntensityUnits: modifyElectricCarbonIntensityUnits = 0,
    isGasInIncludedUtilities: modifyIsGasInIncludedUtilities,
    sqFootage: modifySqFootage,
    doesOfficeHaveGasUtility: modifyDoesOfficeHaveGasUtility,
  } = modifyOffice;

  const findChangedFields = () => {
    const objectFieldsArray = Object.entries(modifyOffice).map(
      ([field, value]) => {
        const originalValue = originalOffice[field];

        if (!value && value !== false) {
          return null;
        }

        if (field === "facilityType") {
          return originalValue.value !== value.value && field;
        }

        if (originalValue !== value) {
          return field;
        }

        return null;
      }
    );

    return objectFieldsArray.filter((value) => value);
  };

  const changedFields = findChangedFields();

  const showUtilitiesConfigWarning = utilitiesInLease
    ? utilitiesConfig !== "utilitiesInLease"
    : utilitiesConfig === "utilitiesInLease";
  const isUtilitiesConfigChange = utilitiesConfig !== initialUtilitiesConfig;

  const buildWarningType = () => {
    const isRenewablesOrIntensityChanged =
      (changedFields.includes("electricRenewablesPercentage") &&
        electricRenewablesPercentage) ||
      (changedFields.includes("electricCarbonIntensity") &&
        electricCarbonIntensity) ||
      (changedFields.includes("electricCarbonIntensityUnits") &&
        electricCarbonIntensityUnits);

    if (changedFields.includes("address")) {
      return "moving";
    }

    if (
      showUtilitiesConfigWarning ||
      (changedFields.includes("sqFootage") &&
        utilitiesConfig === "utilitiesInLease") ||
      (isRenewablesOrIntensityChanged && utilitiesConfig === "utilitiesInLease")
    ) {
      return "date";
    }

    return null;
  };

  const warningType = buildWarningType();

  const enableSaveButtonOnUtilitiesChange = () => {
    if (isUtilitiesConfigChange && utilitiesConfig === "separateUtilities") {
      return !!modifyElectricUtility && !!modifyGasUtility;
    }

    if (isUtilitiesConfigChange && utilitiesConfig === "combinedUtilities") {
      return !!modifyCombinedUtility;
    }

    if (isUtilitiesConfigChange || changedFields.length) {
      return true;
    }

    return false;
  };

  const isElectricRenewablesAndIntensitySaveEnabled =
    electricRenewablesAndIntensitySaveEnabled({
      isElectricRenewablesPercentageGiven: !!modifyElectricRenewablesPercentage,
      isElectricCarbonIntensityGiven: !!modifyElectricCarbonIntensity,
      electricRenewablesPercentage: modifyElectricRenewablesPercentage,
      electricCarbonIntensity: modifyElectricCarbonIntensity,
      electricCarbonIntensityUnits: modifyElectricCarbonIntensityUnits,
    });

  const isSaveEnabled = () => {
    if (!warningType && isElectricRenewablesAndIntensitySaveEnabled) {
      return enableSaveButtonOnUtilitiesChange() || changedFields.length;
    }

    if (warningType === "moving") {
      return false;
    }

    if (!isElectricRenewablesAndIntensitySaveEnabled) {
      return false;
    }

    return !!editsDate && enableSaveButtonOnUtilitiesChange();
  };

  const onDetailsChangeSave = async () => {
    const resetForm = (updateObj) => {
      const mergedOffice = { ...office, ...updateObj };
      const { utilities: currentUtilities } = mergedOffice;
      setModifyOffice({ ...mergedOffice, ...currentUtilities });
      setSelectedOffice({ ...mergedOffice, ...currentUtilities });
      return setSaveLoading(false);
    };

    setSaveLoading(true);
    const updatableFields = changedFields.filter(
      (field) =>
        !["combinedUtility", "gasUtility", "electricUtility"].includes(field)
    );

    const fieldsUpdateObj = Object.fromEntries(
      updatableFields.map((field) => {
        const value = modifyOffice[field];
        return [field, value];
      })
    );

    const buildUtilitiesProps = () => {
      if (utilitiesConfig === "combinedUtilities") {
        return {
          combinedUtility: modifyCombinedUtility,
          doesOfficeHaveGasUtility: modifyDoesOfficeHaveGasUtility,
        };
      }

      if (modifyDoesOfficeHaveGasUtility === false) {
        return {
          electricUtility: modifyElectricUtility,
          doesOfficeHaveGasUtility: modifyDoesOfficeHaveGasUtility,
        };
      }

      return {
        electricUtility: modifyElectricUtility,
        gasUtility: modifyGasUtility,
        doesOfficeHaveGasUtility: modifyDoesOfficeHaveGasUtility,
      };
    };

    const buildNewUtilitiesObj = () => {
      if (utilitiesConfig === "utilitiesInLease") {
        return {
          utilitiesInLease: true,
          isGasInIncludedUtilities: modifyIsGasInIncludedUtilities,
        };
      }

      return { utilitiesInLease: false, ...buildUtilitiesProps() };
    };

    if (warningType === "date" && editsDate) {
      const newUtilities = buildNewUtilitiesObj();

      const changesObj = {
        utilities: newUtilities,
        ...fieldsUpdateObj,
        date: editsDate.toDate(),
      };

      const officeUpdateObj = {
        changes: [changesObj, ...changes],
      };

      await updateCollectionDoc("offices", id, officeUpdateObj);
      return resetForm(changesObj);
    }

    if (
      changedFields.includes("facilityType") ||
      changedFields.includes("electricRenewablesPercentage") ||
      changedFields.includes("electricCarbonIntensity") ||
      changedFields.includes("electricCarbonIntensityUnits") ||
      changedFields.includes("isGasInIncludedUtilities") ||
      changedFields.includes("doesOfficeHaveGasUtility")
    ) {
      const newUtilities = buildNewUtilitiesObj();
      const updateObj = {
        facilityType: modifyFacilityType,
        electricRenewablesPercentage: modifyElectricRenewablesPercentage,
        electricCarbonIntensity: modifyElectricCarbonIntensity,
        electricCarbonIntensityUnits: modifyElectricCarbonIntensityUnits,
        utilities: newUtilities,
        sqFootage: Number(modifySqFootage),
      };
      await updateCollectionDoc("offices", id, updateObj);
      return resetForm(updateObj);
    }

    const updateObj = {
      utilities: { utilitiesInLease: false, ...buildUtilitiesProps() },
      ...fieldsUpdateObj,
    };

    await updateCollectionDoc("offices", id, updateObj);
    return resetForm(updateObj);
  };

  const resetOfficeBackToOriginal = () => setModifyOffice(originalOffice);

  const [firstChange] = changes;

  const closeOfficeWithoutEmployees = (endDate) => {
    closeOffice(office, endDate);
    return setSelectedOffice(null);
  };

  return {
    content: (
      <>
        {!!officeMovingInterfaceOpen && (
          <MovingOfficeInterface
            setOpen={setOfficeMovingInterfaceOpen}
            oldOffice={office}
          />
        )}
        <EditOfficeDetails
          modifyOffice={modifyOffice}
          setModifyOffice={setModifyOffice}
          utilitiesConfig={utilitiesConfig}
          setUtilitiesConfig={setUtilitiesConfig}
          companyStartDate={companyStartDate}
        />
      </>
    ),
    footer: (
      <FooterWithWarnings
        showCloseWarning={showCloseWarning}
        setShowCloseWarning={setShowCloseWarning}
        warningType={warningType}
        saveEnabled={isSaveEnabled()}
        editsDate={editsDate}
        setEditsDate={setEditsDate}
        onSave={onDetailsChangeSave}
        saveLoading={saveLoading}
        mostRecentChangeDate={firstChange?.date}
        resetOfficeBackToOriginal={resetOfficeBackToOriginal}
        setOfficeMovingInterfaceOpen={setOfficeMovingInterfaceOpen}
        officeHasNoEmployees={!currentCommuteEmployees.length}
        closeOfficeWithoutEmployees={closeOfficeWithoutEmployees}
      />
    ),
  };
};

const OfficeDetailsSlider = ({
  office,
  setSelectedOffice,
  setSelectedTransaction,
}) => {
  const {
    id,
    endDate,
    status,
    currentUtilities,
    name,
    address,
    linkedCombinedUtilityId,
    linkedElectricUtilityId,
    linkedGasUtilityId,
    emissions,
    utilitiesConfig,
  } = office;

  const [utilitiesFormOpen, setUtilitiesFormOpen] = useState(false);

  const {
    utilitiesInLease,

    doesOfficeHaveGasUtility,
  } = currentUtilities || {};
  const { state: officeState } = address || {};

  const availableUtilitiesProviders = utilitiesProviders.filter(
    ({ states, utilityId }) =>
      states.includes(officeState) ||
      (utilityId === "DEMO" &&
        process.env.REACT_APP_ENVIRONMENT !== "production")
  );
  const availableUtilitiesProvidersString = availableUtilitiesProviders
    .map(({ name }, idx) =>
      idx !== availableUtilitiesProviders?.length - 1 ? `${name}, ` : `${name}`
    )
    .join("");
  const doesOfficeHaveLinkedUtility =
    linkedCombinedUtilityId || linkedElectricUtilityId || linkedGasUtilityId;

  const [{ isPaid }] = useSubscriptionType("saas");
  const { updateCollectionDoc } = useCachedFirebaseCrud();
  const { content: editOfficeDetails, footer: editOfficeDetailsFooter } =
    useEditOfficeDetails(office, setSelectedOffice);
  const officeStatuses = useOfficeStatuses(endDate);
  const utilityApiFeatures = useFeatureIsOn("utilityapi-features");

  const chartEmissions = emissions
    .map((emission) => {
      const { electricRenewablesReplacementTons, details, source } = emission;

      const warningObj =
        source === "default-utilities"
          ? { warning: officeStatuses.missingEmissions }
          : {};

      const { electricRenewablesReplacementTons: detailsReplacementTons } =
        details || {};

      if (!electricRenewablesReplacementTons && !detailsReplacementTons) {
        return { ...emission, ...warningObj };
      }

      return [
        { ...emission, ...warningObj },
        {
          ...emission,
          subcategory: "renewable-energy",
          tonsCo2e: electricRenewablesReplacementTons || detailsReplacementTons,
        },
      ];
    })
    .flat();

  //TODO: we probably need to update this function so that it updates the office names in the employee commute schedules
  const onNameSave = (name) => updateCollectionDoc("offices", id, { name });

  const generateOfficeDetailsContent = () => {
    const statusObj = officeStatuses[status];

    if (status !== "active" && !emissions.length) {
      return <EmissionsDetailsWarning {...statusObj} />;
    }

    const { tooltip, color, icon } = statusObj;

    const warning =
      status === "active" ? {} : { warning: { text: tooltip, color, icon } };

    const showUtilityPopup =
      availableUtilitiesProviders?.length &&
      !utilitiesInLease &&
      !doesOfficeHaveLinkedUtility &&
      isPaid &&
      utilityApiFeatures;

    return (
      <>
        {utilitiesFormOpen && (
          <LinkUtilitiesForm
            setOpen={setUtilitiesFormOpen}
            utilitiesProvidersList={availableUtilitiesProviders}
          />
        )}
        <Grid container spacing={2} direction="column">
          <Grid item>
            <EmissionsDetails
              {...warning}
              emissions={emissions}
              chartEmissions={chartEmissions}
              setSelectedTransaction={setSelectedTransaction}
              closeSelectedObjectSlider={() => setSelectedOffice(null)}
              type="offices"
              name={name}
            />
          </Grid>
          {!!showUtilityPopup && (
            <Grid item>
              <WarningPopup
                type="success"
                warningTitle={`This office falls under the utility provider${
                  availableUtilitiesProviders?.length > 1 ? "s" : ""
                } ${availableUtilitiesProvidersString}. When you connect ${
                  availableUtilitiesProviders?.length > 1
                    ? "one of these providers"
                    : "this provider"
                }, Aclymate can completely automate your utility emissions, no more manual entry!`}
                warningInput={
                  <Grid
                    container
                    justifyContent="center"
                    direction="row"
                    spacing={2}
                  >
                    {!isPaid && (
                      <Grid item>
                        <AnalyticsFeatureIcon />
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setUtilitiesFormOpen(true)}
                        disabled={!isPaid}
                      >
                        Connect utility provider
                      </Button>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  const detailsView =
    status !== "closed"
      ? [
          {
            label: "Details",
            value: "details",
            content: editOfficeDetails,
            footer: editOfficeDetailsFooter,
          },
        ]
      : [];

  const views = [
    {
      label: "Emissions",
      value: "emissions",
      content: generateOfficeDetailsContent(),
    },
    ...detailsView,
  ];

  const buildFooterInputSubcategories = () => {
    if (doesOfficeHaveGasUtility === false) {
      return [{ subcategory: "electricity" }];
    }

    if (utilitiesConfig === "combinedUtilities") {
      return [{ subcategory: "utilities" }];
    }

    if (utilitiesConfig === "separateUtilities") {
      return [{ subcategory: "gas" }, { subcategory: "electricity" }];
    }

    return [];
  };

  return (
    <SettingsObjectDetailsSlider
      type="offices"
      footerInputSubcategories={[
        { subcategory: "spend-based" },
        ...buildFooterInputSubcategories(),
      ]}
      settingsObject={office}
      setSelectedObject={setSelectedOffice}
      onNameSave={onNameSave}
      views={views}
      noFooter={status === "closed"}
    />
  );
};
export default OfficeDetailsSlider;
